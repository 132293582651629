import React from 'react';
import './App.css';

const App = () => {
  return (
    <div className="iframe-container">
      <iframe
        src="https://192.168.0.243"
        title="Sistema interno"
        sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-forms"
      />
    </div>
  );
};

export default App;
